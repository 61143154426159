void function ($) {
  $(document).ready(function () {
    if ($('.articlesSlider').length){
      var initArticlesSlider = function(){
        var mySwiper = undefined;
    
        function initSwiper() {
          var screenWidth = $(window).width();
    
          if(screenWidth <= 1024 && mySwiper == undefined) {
            $('.articlesSlider').each(function () {
              mySwiper = new Swiper('.articlesSlider', {
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 10,
                grabCursor: true
              });
            })
          } else if (screenWidth > 1024 && mySwiper != undefined) {
            $(mySwiper).each(function (i, item) {
              item.destroy(false, true);
              mySwiper = undefined;
            })
          }
        }
    
        //Swiper plugin initialization
        initSwiper();
    
        //Swiper plugin initialization on window resize
        $(window).on('resize', function(){
          initSwiper();
        });
      }();
    }
    $('.closeMenu').click(function(){
      $(this).parents('.navMain').removeClass('open');
    });
    
    
    $('.openMenu').click(function () {
      if ($(window).width() < 768) {
        $('.navMain').addClass('open');
      } else if ($(window).width() >= 768 && $(window).width() < 1024) {
        $('.navMenu').toggleClass('open');
      }
    });
    
    
    // submenu action
    if ($(window).width() < 1024) {
      var allSubmenus = $('.submenuItem');
    
      $('.openSubmenu').click(function (e) {
        var currentItem = $(e.target).parent('.submenuItem');
        if ($(currentItem).hasClass('open')) {
          $(currentItem).removeClass('open')
        } else {
          allSubmenus.removeClass('open');
          $(this).parent('.submenuItem').addClass('open');
        }
        return false;
      });
    }
    
    // language menu on desktop
    if ($(window).width() >= 1024) {
      window.onclick = function(e) {
        if (e.target.matches('.openSubmenuDk')) {
          $(e.target).parents('.submenuItem').addClass('open')
        } else {
          $('.openSubmenuDk').parents('.submenuItem').removeClass('open')
        }
      }
    }
    
    // sticky navbar
    if ($(window).width() >= 768) {
      var navbar = $('.navMain');
      var sticky = $(navbar).offset();
    
      $(window).on('scroll', function(){
        if (window.pageYOffset >= sticky.top) {
          navbar.addClass('sticky');
        } else {
          navbar.removeClass('sticky');
        }
      });
    }
    var datesDifference = function(){
      var dateDiff = {};
      var datesDelta = Math.abs(new Date('2019-06-02') - new Date()) / 1000;
      var datesScope = {
        weeks: 604800,
        days: 86400,
        hours: 3600,
      };
    
      for (var key in datesScope) {
        dateDiff[key] = Math.floor(datesDelta / datesScope[key]);
        datesDelta -= dateDiff[key] * datesScope[key];
      }
    
      $('.counterDataW').text(dateDiff.weeks);
      $('.counterDataD').text(dateDiff.days);
      $('.counterDataH').text(dateDiff.hours);
    }();
    if ($('.teamsSlider').length) {
      var galleryThumbs = new Swiper('.teamsSliderThumbs', {
        centeredSlides: true,
        spaceBetween: 5,
        slidesPerView: 4,
        touchRatio: 0.2,
        loop: true,
        loopedSlides: 5, //looped slides should be the same
        slideToClickedSlide: true,
        breakpointsInverse: true,
        breakpoints: {
          1024: {
            slidesPerView: 11,
            loopedSlides: 12,
            spaceBetween: 10,
          }
        },
        navigation: {
          nextEl: '.gallery-slide-nav--next',
          prevEl: '.gallery-slide-nav--prev',
        },
      });
    
      var galleryMain = new Swiper('.teamsSlider', {
        loop:true,
        fadeEffect: { crossFade: true },
        loopedSlides: 5,
        effect: 'fade',
        breakpointsInverse: true,
        breakpoints: {
          1024: {
            loopedSlides: 12
          }
        }
      });
    
      galleryMain.controller.control = galleryThumbs;
      galleryThumbs.controller.control = galleryMain;
    }
    if ($('.gallerySlider').length && $(window).width() >= 768){
      var gallerySlider = new Swiper('.gallerySlider', {
        slidesPerView:'auto',
        spaceBetween: 20,
        centeredSlides: true,
        slidesToScroll: 1,
        loop: true,
        navigation: {
          nextEl: '.gallery-slide-nav--next',
          prevEl: '.gallery-slide-nav--prev',
        },
      });
    }
    $('.magnificPopup').magnificPopup({
      type: 'image',
      gallery: {
        enabled: true
      },
    });
    if ($('.battlesSliderTxt').length) {
      var battlesSliderNav = new Swiper('.battlesSliderNav', {
        spaceBetween: 0,
        slidesPerView: 4,
        slidesToScroll: 1,
        breakpointsInverse: true,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        breakpoints: {
          1024: {
            slidesPerView: 6,
            slidesToScroll: 1,
          }
        }
      });
    
      var battlesSliderTxt = new Swiper('.battlesSliderTxt', {
        effect: 'fade',
        fadeEffect: { crossFade: true },
        breakpointsInverse: true,
        slidesPerView: 1,
        slidesToScroll: 1,
        autoHeight: true,
        navigation: {
          nextEl: '.swiperNav-next',
          prevEl: '.swiperNav-prev',
        },
      });
    
      battlesSliderTxt.controller.control = battlesSliderNav;
      battlesSliderNav.controller.control = battlesSliderTxt;
    }
    
    if ($('.battlesSubslider').length) {
      var battlesSubslider = new Swiper('.battlesSubslider', {
        direction: 'horizontal',
        slidesPerView: "auto",
        loop: true,
        centeredSlides: true,
        speed: 2000,
        spaceBetween: 10,
        slideToClickedSlide: true,
        breakpointsInverse: true,
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
        breakpoints: {
          1024: {
            slidesPerView: 6,
            slidesToScroll: 1,
            spaceBetween: 30,
          }
        }
      });
    }
    
    if ($('.battlesSubsliderCtrl').length) {
      var battlesSubsliderCtrl = new Swiper('.battlesSubsliderCtrl', {
        slidesPerView: 1,
        slidesToScroll: 1,
        loop: true,
        speed: 1000,
        spaceBetween: 10,
        breakpointsInverse: true,
        navigation: {
          nextEl: '.gallery-slide-nav--next',
          prevEl: '.gallery-slide-nav--prev',
        },
        breakpoints: {
          560: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: "auto",
            spaceBetween: 20,
          }
        }
      });
    }
    (function() {
      var allPanels = $('.accPanel');
    
      allPanels.each(function(i, item){
        if (!$(item).hasClass('active')) {
          $(this).hide();
        } else {
          $(this).show();
        }
      });
    
      $('.accTitle').click(function(e) {
        e.preventDefault();
    
        var $this = $(this);
    
        if ($this.next().hasClass('active')) {
          $this.next().removeClass('active');
          $this.next().slideUp(350);
          $this.removeClass('active');
        } else {
          $this.closest('.accItem').find('.accTitle').removeClass('active');
          $this.closest('.accItem').find('.accPanel').removeClass('active').slideUp(350);
          $this.next().toggleClass('active');
          $this.next().slideToggle(350);
          $this.toggleClass('active');
        }
      });
    })();
    $(".openSearch").on('click', function () {
      $(this).parents('.searchField').addClass('active');
      return false;
    });
    
    $(document).on('click', function (e) {
      if($(e.target).closest('.searchfieldGroup').length === 0) {
        $('.searchField').removeClass('active');
      }
    });
    var openAsideBar = function(){
      if($('.asideBar').length) {
        if ($(window).width() < 1024) {
          // open sidebar
          $(document).on('click', function (e) {
            if ($(e.target).closest('.openSidebar').length) {
              if (!$('.openSidebar').hasClass('active')) {
                $('.asideBar').addClass('open');
                $('body').addClass('sidebar-open');
              }
            } else if ($(e.target).closest('.closeSidebar').length) {
              $('.asideBar').removeClass('open');
              $('body').removeClass('sidebar-open');
            }
          });
    
          // stick controls for sidebar
          var sidebar = $('.asideBarPos');
    
          if ($(window).width() < 768) {
            var topStep = $('.headerInner').outerHeight() + 20;
          } else {
            var topStep = $('.navMain').outerHeight() + 20;
          }
    
          var stickIt = $(sidebar).offset().top - topStep;
    
          $(window).on('scroll', function () {
            if (window.pageYOffset >= stickIt) {
              sidebar.addClass('sticky').css({'top': topStep});
            } else {
              sidebar.removeClass('sticky').css({'top': 'auto'});
            }
          });
        }
      }
    }();
    $('.tabLink').click(function(e){
      var getTabId = $(this).attr('data-href');
    
      if(!$(this).hasClass('active')) {
        $('.tabLink').each(function () {
          $(this).removeClass('active');
    
          if ($(this)[0] == e.target) {
            $(this).addClass('active')
          }
        });
    
        $('.tabContent').each(function () {
          $(this).hide().removeClass('active');
          if ($(this).attr('id') == getTabId) {
            $(this).fadeIn().addClass('active');
          }
        })
      }
    });
    $('.clickCopy').click(function(){
      var getText = $(this).find('.clickCopyTxt')
      var $tempInput = $("<input>");
      $('body').append($tempInput);
      $tempInput.val($(getText).text()).select();
      document.execCommand("copy");
      $tempInput.remove();
    });

    $(function () {
      objectFitImages()
    }); // init object-fill polyfill

    if (document.documentMode || /Edge/.test(navigator.userAgent)) {
      $('.ieHover').hover(function () {
        $(this).addClass('ie-hover');
      }, function () {
        $(this).removeClass('ie-hover');
      })
    }
  });

if($(window).width() <= 768) {
  window.onscroll = function () {
    scrollFunction()
  };

  function scrollFunction() {
    if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
      document.getElementById('scrollTop').classList.add('show');
    } else {
      document.getElementById('scrollTop').classList.remove('show');
    }
  }

  $('#scrollTop').click(function(){
    $('html, body').animate({ scrollTop: 0 }, 'slow');
    return false;
  });
}
}(jQuery)